var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticStyle: { "border-right": "1px solid" } },
              [
                _c("document", {
                  attrs: {
                    model: _vm.documentAResult.documentContent,
                    source: "left",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              [
                _c("document", {
                  attrs: {
                    model: _vm.documentBResult.documentContent,
                    source: "right",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }