import { render, staticRenderFns } from "./document.vue?vue&type=template&id=60f70e6a&scoped=true&"
import script from "./document.vue?vue&type=script&lang=js&"
export * from "./document.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f70e6a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/macos/workspaces/winning2024/pdf-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60f70e6a')) {
      api.createRecord('60f70e6a', component.options)
    } else {
      api.reload('60f70e6a', component.options)
    }
    module.hot.accept("./document.vue?vue&type=template&id=60f70e6a&scoped=true&", function () {
      api.rerender('60f70e6a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/demo/jsonDiff/document.vue"
export default component.exports