<template>
  <div>
    <el-row v-for="(item,key) in model">
      <el-col :span="8" v-if="item.contents && Object.keys(item.contents).length>0">
        <div v-html="getShowStyle(item,'name')"></div>
      </el-col>
      <el-col :span="16" v-if="item.contents && Object.keys(item.contents).length>0">
        <ul>
          <li v-for="c in item.contents">
            <div v-html="getShowStyle(c,'content')"></div>
          </li>
        </ul>
      </el-col>
      <el-col :span="24" v-if="item.contents && Object.keys(item.contents).length==0">
        <div v-html="getShowStyle(item,'name')"></div>
      </el-col>

      <el-row style="margin-left: 40px;">
        <document :model="item.child" :source="source"></document>
      </el-row>

    </el-row>
  </div>

</template>
<script>

export default {
  name: 'document',
  computed: {},
  props: {
    model: {
      type: Object
    },
    //source  left/right
    source: {type: String}
  },
  data() {
    return {}
  },
  created() {
    //console.log("document组件",this.source)

  },
  mounted() {

  },
  methods: {
    getShowStyle(item, type) {

      let editTypeKey = "editType_"

      let editType = item[editTypeKey + type]
      let result = item[type]
      if (editType && editType.type == '新增' && this.source == 'right') {
        result = '<span style="color: #00AB44">' + result + '</span>'
      } else if (editType && editType.type == '删除' && this.source == 'right') {
        result = '<span style="color: #fc0000;text-decoration: line-through">' + result + '</span>'
      } else if (editType && editType.type == '删除' && this.source == 'left') {
        result = '<span style="display: inline-block;border: 1px solid #a9f8f8;width: 100%;height: 25px;"></span>'
      } else if (editType && editType.type == '修改') {
        result = '<span style="color: #0139f8">' + editType.right + '</span>'
      }
      return result
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
