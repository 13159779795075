var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.model, function (item, key) {
      return _c(
        "el-row",
        [
          item.contents && Object.keys(item.contents).length > 0
            ? _c("el-col", { attrs: { span: 8 } }, [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.getShowStyle(item, "name")),
                  },
                }),
              ])
            : _vm._e(),
          item.contents && Object.keys(item.contents).length > 0
            ? _c("el-col", { attrs: { span: 16 } }, [
                _c(
                  "ul",
                  _vm._l(item.contents, function (c) {
                    return _c("li", [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getShowStyle(c, "content")),
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          item.contents && Object.keys(item.contents).length == 0
            ? _c("el-col", { attrs: { span: 24 } }, [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.getShowStyle(item, "name")),
                  },
                }),
              ])
            : _vm._e(),
          _c(
            "el-row",
            { staticStyle: { "margin-left": "40px" } },
            [
              _c("document", {
                attrs: { model: item.child, source: _vm.source },
              }),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }