import { render, staticRenderFns } from "./jsonDiff.vue?vue&type=template&id=4e1fd086&scoped=true&"
import script from "./jsonDiff.vue?vue&type=script&lang=js&"
export * from "./jsonDiff.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e1fd086",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/macos/workspaces/winning2024/pdf-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4e1fd086')) {
      api.createRecord('4e1fd086', component.options)
    } else {
      api.reload('4e1fd086', component.options)
    }
    module.hot.accept("./jsonDiff.vue?vue&type=template&id=4e1fd086&scoped=true&", function () {
      api.rerender('4e1fd086', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/demo/jsonDiff/jsonDiff.vue"
export default component.exports