<!--版本比较-->
<!--http://tangshisanbaishou.xyz/diff/index.html-->
<!--http://config.net.cn/tools/JsonDiff.html#-->
<!--https://github.com/dianluyuanli-wp/jsDiffWeb-->
<!--https://github.com/kpdecker/jsdiff-->
<template>
  <div>

    <el-row>
      <el-col :span="12">
        <div style="border-right: 1px solid">
          <document :model="documentAResult.documentContent" source="left"></document>
        </div>
      </el-col>
      <el-col :span="12">
        <div>
          <document :model="documentBResult.documentContent" source="right"></document>
        </div>
      </el-col>
    </el-row>
  </div>

</template>
<script>


import document from './document'
export default {
  computed: {},
  components: {document},
  data() {
    return {
      total: 0,
      documentA: {
        "id": 101001,
        "source": 1,
        "templateName": "通用药品说明书",
        "documentTag": [{
          "name": "药品说明书"
        }],
        "createBy": "卫心科技",
        "createTime": "2021-09-27 02:16",
        "updateBy": "卫心科技",
        "updateTime": "2021-10-27 02:16",
        "documentContent": [{
          "name": "药品名称",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "child": [{
            "name": "通用名",
            "code": "6fcbb862f69b410c9cc41b58ffeaefb3",
            "contents": [{
              "content": "阿伐他汀钙片",
              "index": 1,
              "referenceInfos": [{
                "id": 1987,
                "resourceId": 199069,
                "resourceName": "阿伐他汀钙片（立普妥）（辉瑞制药）20mg*7片说明书",
                "resourceTypeId": 2,
                "resourceTypeName": "药品说明书"
              }]
            }],
            "child": []
          }, {
            "name": "英文名",
            "code": "6fcff862f69b410c9cc41b58ffeaefa4",
            "contents": [{
              "content": "Atorvastatin Calcium Tablets",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }, {
            "name": "汉语拼音",
            "code": "6fcff862f69b410c9cc43f58ffeaefa4",
            "contents": [{
              "content": "Afatating Gai Pian",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }],
          "contents": []
        }, {
          "name": "成分",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "child": [{
            "name": "化学名称",
            "code": "f15e4b2ca959418d9c1dee63a8b4660e",
            "contents": [{
              "content": "[R-（R',R'）]-2-（4-氟苯基）-β·δ-二羟基-5-(1-甲基乙基)- 3-苯基-4-[(苯胺)羰基]-1-氢-吡咯-1-庚酸钙三水合物",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }, {
            "name": "结构式",
            "code": "f15e4b2ca959418d6a1dee63a8b4660e",
            "contents": [{
              "content": "暂无图片",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }, {
            "name": "分子式",
            "code": "f15e4b2ca959415afa1dee63a8b4660e",
            "contents": [{
              "content": "( C33H34 FN2O5)2Ca·3H2O",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }, {
            "name": "分子量",
            "code": "f15e4b2ca089415afa1dee63a8b4660e",
            "contents": [{
              "content": "1209.42",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }],
          "contents": []
        }, {
          "name": "性状",
          "code": "dfc24b7e9f40432ab9167a815b01a1d8",
          "contents": [{
            "content": "本品为白色薄膜衣片,除去膜衣显白色。",
            "index": 1,
            "referenceInfos": []
          }],
          "child": []
        }, {
          "name": "适应症",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "contents": [{
            "content": "用于治疗高胆固醇血症和混合型高脂血症；冠心病和脑中风的防治。",
            "index": 1,
            "referenceInfos": []
          }],
          "child": []
        }, {
          "name": "规格",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "contents": [{
            "content": "20mg",
            "index": 1,
            "referenceInfos": []
          }]
        }, {
          "name": "用法用量",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "contents": [{
            "content": "成人常用量口服：10-20mg，每日1次，晚餐时服用。剂量可按需要调整，但最大剂量不超过每日80mg。",
            "index": 1,
            "referenceInfos": []
          }],
          "child": []
        }]
      },

      documentB: {
        "id": 101001,
        "source": 1,
        "templateName": "通用药品说明书",
        "documentTag": [{
          "name": "药品说明书"
        }],
        "createBy": "卫心科技",
        "createTime": "2021-09-27 02:16",
        "updateBy": "卫心科技",
        "updateTime": "2021-10-27 02:16",
        "documentContent": [{
          "name": "药品名称",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "child": [{
            "name": "通用名",
            "code": "6fcbb862f69b410c9cc41b58ffeaefb3",
            "contents": [{
              "content": "阿伐他汀钙片（30mg）",
              "index": 1,
              "referenceInfos": [{
                "id": 1987,
                "resourceId": 199069,
                "resourceName": "阿伐他汀钙片（立普妥）（辉瑞制药）20mg*7片说明书",
                "resourceTypeId": 2,
                "resourceTypeName": "药品说明书"
              }]
            }],
            "child": []
          }, {
            "name": "英文名",
            "code": "6fcff862f69b410c9cc41b58ffeaefa4",
            "contents": [{
              "content": "Atorvastatin Calcium Tablets",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }],
          "contents": []
        }, {
          "name": "成分",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "child": [{
            "name": "化学名称",
            "code": "f15e4b2ca959418d9c1dee63a8b4660e",
            "contents": [{
              "content": "[R-（R',R'）]-2-（4-氟苯基）-β·δ-二羟基-5-(1-甲基乙基)- 3-苯基-4-[(苯胺)羰基]-1-氢-吡咯-1-庚酸钙三水合物",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }, {
            "name": "结构式",
            "code": "f15e4b2ca959418d6a1dee63a8b4660e",
            "contents": [{
              "content": "暂无图片",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }, {
            "name": "分子式",
            "code": "f15e4b2ca959415afa1dee63a8b4660e",
            "contents": [{
              "content": "( C33H34 FN2O5)2Ca·3H2O",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }, {
            "name": "分子量",
            "code": "f15e4b2ca089415afa1dee63a8b4660e",
            "contents": [{
              "content": "1209.42",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }, {
            "name": "分子量2",
            "code": "f15e4b2ca089415afa1dee63a8b4660e",
            "contents": [{
              "content": "345.45",
              "index": 1,
              "referenceInfos": []
            }],
            "child": []
          }],
          "contents": []
        }, {
          "name": "性状",
          "code": "dfc24b7e9f40432ab9167a815b01a1d8",
          "contents": [{
            "content": "本品为白色薄膜衣片,除去膜衣显白色。",
            "index": 1,
            "referenceInfos": []
          }],
          "child": []
        }, {
          "name": "适应症",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "contents": [{
            "content": "用于治疗高胆固醇血症和混合型高脂血症；冠心病和脑中风的防治。",
            "index": 1,
            "referenceInfos": []
          }],
          "child": []
        }, {
          "name": "规格",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "contents": [{
            "content": "20mg",
            "index": 1,
            "referenceInfos": []
          }]
        }, {
          "name": "用法用量",
          "code": "df344b7e9f40432ab9167a815b01a1d8",
          "contents": [{
            "content": "成人常用量口服：10-20mg，每日1次，晚餐时服用。剂量可按需要调整，但最大剂量不超过每日80mg。",
            "index": 1,
            "referenceInfos": []
          }],
          "child": []
        }]
      },
      documentAResult:{},
      documentBResult:{}

    }
  },
  created() {
    //this.diff()
    this.documentAResult=this.compareJson(this.documentB,this.documentA,'root')
    this.documentBResult=this.compareJson(this.documentA,this.documentB,'root')
    console.log("documentAResult",this.documentAResult.documentContent)
    console.log("documentBResult",this.documentBResult.documentContent)
  },
  mounted() {

  },
  methods: {
    compareTree(leftObj, rightObj, name, thisNode) {
      var leftObjType = this.type(leftObj);
      var rightObjType = this.type(rightObj);
      let editTypeKey = "editType_"

      var str1 = (leftObjType === 'object' || leftObjType === 'array') ? '' : String(leftObj) + ' ';
      var str2 = (rightObjType === 'object' || rightObjType === 'array') ? '' : String(rightObj) + ' ';

      // Set diff value
      if (leftObj === undefined) {

        if (str1 == "undefined " && str2 == '') {
        } else {
          thisNode[name] = str2
          thisNode[editTypeKey + name] = {
            "type": '新增'
          }
        }


      } else if (rightObj === undefined) {

        thisNode[name] = str1
        thisNode[editTypeKey + name] = {
          "type": '删除'
        }

      } else if (leftObjType !== rightObjType
        || (leftObjType !== 'object' && leftObjType !== 'array' && leftObj !== rightObj)) {

        thisNode[name] = str1 + '=> ' + str2
        thisNode[editTypeKey + name] = {
          "type": '修改',
          "left": str1,
          "right": str2
        }

      } else {

        if (str1 == '' && str2 == '') {

        } else {
          thisNode[name] = str1
        }

      }


      if (leftObjType === 'object' || leftObjType === 'array'
        || rightObjType === 'object' || rightObjType === 'array') {
        var keys = [];

        // Collect al of the keys of leftObj and rightObj
        for (var i in leftObj) {
          if (leftObj.hasOwnProperty(i)) {
            keys.push(i);
          }
        }

        for (var i in rightObj) {
          if (rightObj.hasOwnProperty(i)) {
            keys.push(i);
          }
        }

        // the index of array must convert into integer
        if ('array' === this.type(leftObj)) {
          for (var i = 0; i < keys.length; ++i) {
            keys[i] = parseInt(keys[i], 10);
          }
          keys.sort(function (a, b) {
            return a - b;
          });
        } else {
          keys.sort();
        }


        for (var i = 0; i < keys.length; i++) {
          // unique key filter
          let keysI = keys[i]

          if (keysI === keys[i - 1]) {
            continue;
          }


          var liNode = {}


          //如果是字符串，传递原始thisNode，如果是对象或者数组，传递引用
          var leftChildObjType = this.type(leftObj && leftObj[keysI]);
          var rightChildObjType = this.type(rightObj && rightObj[keysI]);

          if (leftChildObjType === 'object' || leftChildObjType === 'array'
            || rightChildObjType === 'object' || rightChildObjType === 'array') {
            thisNode[keysI] = {}
            this.compareTree(leftObj && leftObj[keysI],
              rightObj && rightObj[keysI], keysI, thisNode[keysI]);
          } else {
            thisNode[keysI] = liNode
            this.compareTree(leftObj && leftObj[keysI],
              rightObj && rightObj[keysI], keysI, thisNode);
          }
          //console.log("--",leftObj && leftObj[keys[i]], rightObj && rightObj[keys[i]], keysI)


        }


      } else {

      }
    },
    compareJson(left, right, key) {
      let result = {}
      this.compareTree(left, right, key, result);
      return result
    },
    /**
     * 获取对象类型
     * @param  {Object} obj object
     * @return {string}
     */
    type(obj) {
      return this.isArray(obj) ? 'array' : typeof obj;
    },
    /**
     * 判断是否为数组
     * @param  {Object}  obj 待判断的对象
     * @return {boolean}     true/false
     */
    isArray(obj) {
      return obj && 'object' === typeof obj && Array === obj.constructor;
    }

  }
}
</script>
<style lang="scss" scoped>

</style>
